import { API_ASSIGNATION_CSV_EXPORT_URL } from "@daytrip/legacy-config";
import { UserBalance } from "@legacy/dataTransferObjects/UserBalance";
import { AssignationDataForPayout } from "@legacy/domain/AssignationDataForPayout";
import { Currency } from "@legacy/domain/Currency";
import { DriverDebt } from "@legacy/models/DriverDebt";
import { RetrieveAssignationsOptions } from "@legacy/options/RetrieveAssignationsOptions";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, observable } from "mobx";

import { convertLocalToUTCDate } from "@utils/date/convertLocalToUTCDate";

import { PageStore } from "../../stores/PageStore";

import type { DriversAssignationsPageRouter } from "./DriversAssignationsPageRouter";

@autobind
export class DriversAssignationsPageStore extends PageStore<DriversAssignationsPageRouter, {}> {
    @observable
    public debts?: Array<DriverDebt>;

    @observable
    public balance?: UserBalance;

    @observable
    public completedTripsCurrency: Currency = Currency.Euro;

    @action
    public async fetchCompletedTripsCurrency(driverId?: string): Promise<void> {
        if (driverId) {
            const { countryId } = await this.rpcClient.user.retrieveUser(driverId);
            const currency = await this.rpcClient.driver.getDriverCompletedTripsCurrency(countryId);
            this.completedTripsCurrency = currency;
        }
    }

    @action
    public async onFetchData() {
        if (this.pageRouter.departureAtFrom && this.pageRouter.departureAtTo) {
            const df = this.pageRouter.departureAtFrom;
            const dt = this.pageRouter.departureAtTo;

            if (
                df.getFullYear() === dt.getFullYear() &&
                df.getMonth() === dt.getMonth() &&
                df.getDate() === dt.getDate()
            ) {
                df.setHours(0);
                df.setMinutes(0);
                df.setSeconds(0);
                this.pageRouter.filterDepartureAtFromUpdate(df.getTime().toString());

                dt.setHours(23);
                dt.setMinutes(59);
                dt.setSeconds(59);
                this.pageRouter.filterDepartureAtToUpdate(dt.getTime().toString());
            }
        }

        this.fetchAssignationsData();
        this.fetchCompletedTripsCurrency(this.pageRouter.driverId);
        this.fetchUserBalance();
        this.fetchDriversDebts();
    }

    @computed
    public get assignationsOptions(): RetrieveAssignationsOptions {
        const { driverIds, departureAtFrom, departureAtTo, skip, limit, sortBy, sortDirection } = this.pageRouter;

        return {
            driverIds,
            departureAtFrom: convertLocalToUTCDate(departureAtFrom),
            departureAtTo: convertLocalToUTCDate(departureAtTo),
            skip,
            limit,
            sortBy,
            sortDirection,
        } as RetrieveAssignationsOptions;
    }

    @observable
    public assignationsDataForPayout?: Array<AssignationDataForPayout>;

    @observable
    public assignationsDataForPayoutCount?: number;

    @action
    public async fetchAssignationsData(): Promise<void> {
        this.assignationsDataForPayout = plainToClass(
            AssignationDataForPayout,
            await this.rpcClient.assignation.retrieveDriverAssignationsForPayout(this.assignationsOptions),
        );

        this.assignationsDataForPayoutCount = await this.rpcClient.assignation.retrieveAssignationsCount(
            this.assignationsOptions,
        );
    }

    @action
    public async fetchUserBalance(): Promise<void> {
        [this.balance] = await this.rpcClient.payout.retrieveUsersBalances({
            userIds: this.pageRouter.driverIds,
            departureAtFrom: convertLocalToUTCDate(this.pageRouter.departureAtFrom),
            departureAtTo: convertLocalToUTCDate(this.pageRouter.departureAtTo),
        });
    }

    @action
    public async fetchDriversDebts(): Promise<void> {
        this.debts = plainToClass(
            DriverDebt,
            await this.rpcClient.payout.retrieveDriversDebts({
                driversIds: this.pageRouter.driverIds,
                hasPayout: false,
            }),
        );
    }

    public isDataFetched(): this is DriversAssignationsPageStore & DriversAssignationsPageStoreDataFetched {
        return (
            this.assignationsDataForPayout != null &&
            this.assignationsDataForPayoutCount != null &&
            this.pageRouter.driverId != null
        );
    }

    public downloadAsCSV() {
        const { authenticationToken } = this.authenticationStore;
        window.location.href = `${API_ASSIGNATION_CSV_EXPORT_URL}?authenticationToken=${authenticationToken}&options=${JSON.stringify(
            this.assignationsOptions,
        )}`;
    }
}

export interface DriversAssignationsPageStoreDataFetched {
    assignationsDataForPayout: Array<AssignationDataForPayout>;
    assignationsDataForPayoutCount: number;
}
