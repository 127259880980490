import type { HostAgencyAndTravelAgentCount } from "@daytrip/legacy-models";
import { SimpleTravelAgent } from "@legacy/domain/SimpleTravelAgent";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { Country } from "@legacy/models/Country";
import type { TravelAgent } from "@legacy/models/TravelAgent";
import type { RetrieveTravelAgentsOptions } from "@legacy/options/RetrieveTravelAgentsOptions";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { TravelAgentsPageRouter } from "./TravelAgentsPageRouter";

export enum SelectedTravelAgentsActions {
    assignHostAgency = "assignHostAgency",
}

@autobind
export class TravelAgentsPageStore extends PageStore<TravelAgentsPageRouter, {}> {
    @observable
    public travelAgents?: Array<SimpleTravelAgent>;

    @observable
    public editedTravelAgentModel?: SimpleTravelAgent;

    @observable
    public travelAgentsCount: number = 0;

    @observable
    public owners?: Array<SimpleUser>;

    @observable
    public countries?: Array<Country>;

    @observable
    public hostAgencies?: HostAgencyAndTravelAgentCount[];

    @observable
    public selectedTravelAgentIds: Array<string> = [];

    @observable
    public isAssignHostAgencyModalOpen: boolean = false;

    @observable
    public selectedTravelAgentsActionInProgress?: SelectedTravelAgentsActions = undefined;

    @observable
    public isMultipleSelectRevealed = false;

    @action
    public toggleMultipleSelect(): void {
        this.isMultipleSelectRevealed = !this.isMultipleSelectRevealed;
        this.selectedTravelAgentIds = [];
    }

    @action
    public toggleSelectAllVisibleTravelAgents(): void {
        if (this.travelAgents) {
            if (this.isAllVisibleTravelAgentsSelected) {
                this.selectedTravelAgentIds = [];
            } else {
                const selectedTravelAgentIds = this.travelAgents.map((travelAgent) => travelAgent.userId);
                this.selectedTravelAgentIds = selectedTravelAgentIds;
            }
        }
    }

    @computed
    public get isAllVisibleTravelAgentsSelected(): boolean {
        return (
            this.travelAgents?.length ===
            this.travelAgents?.filter((travelAgent) => this.selectedTravelAgentIds.includes(travelAgent.userId)).length
        );
    }

    @action
    public toggleTravelAgentSelect(travelAgentId: string): void {
        const exists = this.selectedTravelAgentIds.includes(travelAgentId);

        if (exists) {
            this.selectedTravelAgentIds = this.selectedTravelAgentIds.filter(
                (selectedtravelAgentId) => selectedtravelAgentId !== travelAgentId,
            );
        } else {
            this.selectedTravelAgentIds = [...this.selectedTravelAgentIds, travelAgentId];
        }
    }

    @action
    public async copySelectedTravelAgentIdsToClipboard() {
        navigator.clipboard.writeText(this.selectedTravelAgentIds.join(","));
    }

    @action.bound
    async assignHostAgencyToSelectedTravelAgents(hostAgencyId: string) {
        if (!this.selectedTravelAgentIds.length) {
            return;
        }

        this.selectedTravelAgentsActionInProgress = SelectedTravelAgentsActions.assignHostAgency;

        await this.rpcClient.travelAgent.bulkUpdate(this.selectedTravelAgentIds, { hostAgencyId });

        this.closeAssignHostAgencyModal();
        await this.fetchContent();
        this.selectedTravelAgentIds = [];
        this.selectedTravelAgentsActionInProgress = undefined;
    }

    @action
    public openAssignHostAgencyModal = () => {
        if (!this.authenticationStore.hasPermissions("User_Customer:Modify")) {
            return;
        }
        this.isAssignHostAgencyModalOpen = true;
    };

    @action
    public closeAssignHostAgencyModal = () => {
        this.resetAssignHostAgencyState();
    };

    @action
    public resetAssignHostAgencyState(): void {
        this.isAssignHostAgencyModalOpen = false;
        this.selectedTravelAgentsActionInProgress = undefined;
        this.selectedTravelAgentIds = [];
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.travelAgents = undefined;

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            search: this.pageRouter.search,
            statusIn: this.pageRouter.statusIn,
            ownerIds: this.pageRouter.ownerIds,
            countryIds: this.pageRouter.countryIds,
            hostAgencyIds: this.pageRouter.hostAgencyIds,
            partnerBookingAvailable: this.pageRouter.partnerBookingAvailable,
            includeTravelAgentsWithoutCountry: this.pageRouter.includeTravelAgentsWithoutCountry,
            travelAgentCategory: this.pageRouter.travelAgentCategories,
        } as RetrieveTravelAgentsOptions;

        let hostAgenciesPaginated;

        [this.travelAgents, this.travelAgentsCount, this.owners, this.countries, hostAgenciesPaginated] =
            await Promise.all([
                this.rpcClient.travelAgent.retrieveTravelAgents(options),
                this.rpcClient.travelAgent.retrieveTravelAgentsCount(options),
                this.rpcClient.travelAgent.retrieveTravelAgentsOwners(),
                this.rpcClient.content.retrieveCountries({}),
                this.rpcClient.hostAgency.retrieveHostAgenciesAndTravelAgentCount({}),
            ]);

        this.hostAgencies = hostAgenciesPaginated.data;
    }

    @action
    public async approveTravelAgent(id: string) {
        await this.rpcClient.travelAgent.approveTravelAgent(id);

        try {
            await this.rpcClient.klaviyo.createNewProfile(id);
        } catch (err) {
            alert("Failed to create Klaviyo profile for the travel agent.");
            console.error(err);
        }
        this.fetchContent();
    }

    @action
    public async declineTravelAgent(id: string, reason: string) {
        await this.rpcClient.travelAgent.declineTravelAgent(id, reason);
        this.fetchContent();
    }

    @action
    public async deleteTravelAgent(id: string) {
        await this.rpcClient.travelAgent.deleteTravelAgent(id);
        this.fetchContent();
    }

    public loadHostAgencyOptions = async (query: string) => {
        const hostAgencies = await this.rpcClient.hostAgency.retrieveHostAgencies();

        return {
            options: hostAgencies
                .filter((hostAgency) => hostAgency.name.toLowerCase().includes(query.toLowerCase()))
                .map((hostAgency) => ({
                    value: hostAgency._id,
                    label: hostAgency.name,
                })),
        };
    };

    @computed
    public get isAnythingLoading(): boolean {
        return this.selectedTravelAgentsActionInProgress !== undefined;
    }

    public isDataFetched(): this is TravelAgentsPageStore & TravelAgentsPageStoreDataFetched {
        return this.travelAgents !== undefined && this.owners !== undefined;
    }
}

export interface TravelAgentsPageStoreDataFetched {
    travelAgents: Array<TravelAgent>;
    owners: Array<SimpleUser>;
}
